import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { DtsStateModel } from '../../../types/documents/dts';
import {
  DtsAddWareItem,
  DtsDeleteWareItems,
  DtsReplaceWares,
  DtsUpdateMainForm,
  DtsUpdateWareItems,
  SetDtsDocument,
} from './dts.actions';

@State<DtsStateModel>({
  name: 'dts',
  defaults: {
    selectedDocument: null,
    selectedDocumentWares: [],
  },
})
@Injectable()
export class DtsState {
  @Selector()
  static getSelectedDocument(state: DtsStateModel) {
    return state.selectedDocument;
  }

  @Selector()
  static getMainInfoJson(state: DtsStateModel) {
    return state.selectedDocument?.mainForm?.mainFormJson;
  }

  @Selector()
  static getSelectedDocumentWares(state: DtsStateModel) {
    return state.selectedDocumentWares;
  }

  @Action(DtsUpdateMainForm)
  updateBlock(ctx: StateContext<DtsStateModel>, action: DtsUpdateMainForm) {
    ctx.setState(
      produce(draft => {
        if (draft.selectedDocument?.mainForm) {
          draft.selectedDocument.mainForm = action.block;
        }
      }),
    );
  }

  @Action(SetDtsDocument)
  setDtImportDocument(
    ctx: StateContext<DtsStateModel>,
    action: SetDtsDocument,
  ) {
    const { wares, ...other } = action.declaration;
    ctx.patchState({
      selectedDocumentWares: wares,
      selectedDocument: other,
    });
  }

  @Action(DtsAddWareItem)
  dtsAddWareItem(ctx: StateContext<DtsStateModel>, action: DtsAddWareItem) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocumentWares?.push(action.wareItem);
      }),
    );
  }

  @Action(DtsUpdateWareItems)
  dtsUpdateWareItems(
    ctx: StateContext<DtsStateModel>,
    action: DtsUpdateWareItems,
  ) {
    ctx.setState(
      produce(draft => {
        action.wareItems.forEach(wareItem => {
          const id = wareItem.id;
          const index = ctx
            .getState()
            .selectedDocumentWares.findIndex(i => i.id === id);
          if (index !== null) {
            draft.selectedDocumentWares.splice(index, 1, wareItem);
          }
        });
      }),
    );
  }

  @Action(DtsDeleteWareItems)
  dtsDeleteWareItems(
    ctx: StateContext<DtsStateModel>,
    action: DtsDeleteWareItems,
  ) {
    ctx.setState(
      produce(draft => {
        const items = draft.selectedDocumentWares.filter(
          item => !action.deletedWareItemsIds.includes(item.id),
        );
        draft.selectedDocumentWares.splice(0);
        draft.selectedDocumentWares.push(...items);
      }),
    );
  }

  @Action(DtsReplaceWares)
  dtsReplaceWares(ctx: StateContext<DtsStateModel>, action: DtsReplaceWares) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocumentWares = action.wareItems;
      }),
    );
  }
}
